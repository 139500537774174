'use client';

import { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useParams, usePathname } from 'next/navigation';
import Icon from '#/components/icon';
import NavMobile from './nav-mobile';
import NavResources from './nav-resources';
import { getTheme } from '#/lib/themes';
import ButtonArrow from './button-arrow';

export default function Header() {
  const params = useParams();
  const pathname = usePathname();
  let theme = params.hasOwnProperty('theme') ? getTheme(params.theme) : null;

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showResources, setShowResources] = useState(false);

  const resourcesMenu = useRef<any>(null);
  const mobileMenu = useRef<any>(null);

  useEffect(() => {
    setShowResources(false);
  }, [pathname]);

  useEffect(() => {
    const handleOutSideClick = (event: Event) => {
      let target = event?.target as HTMLElement;
      if (
        resourcesMenu.current &&
        !resourcesMenu.current?.contains(event.target) &&
        !target.closest('button#resources-toggle')
      ) {
        setShowResources(false);
      }
    };

    // Click event
    window.addEventListener('click', handleOutSideClick);
    return () => {
      window.removeEventListener('click', handleOutSideClick);
    };
  }, [resourcesMenu, showResources, ]);

  return (
    <header className="sticky top-0 z-[100] bg-accent-header-bg px-4 py-3 font-normal text-accent-txt-2 backdrop-blur-lg md:py-4">
      <nav className="mx-auto flex max-w-5xl flex-wrap items-center justify-between">
        {/* Brand */}
        <Link href="/" className="flex items-center">
          <Image
            src="/bright-themes.svg"
            className="mr-2 h-8"
            width={32}
            height={32}
            alt="Bright Themes Logo"
          />
          <span
            className="hidden self-center whitespace-nowrap text-lg font-normal sm:block"
            aria-label="Bright Themes"
          >
            Bright Themes
          </span>
        </Link>

        {/* Navigation */}
        <div className="flex items-center md:order-2">
          <div
            className="mr-2 hidden w-full md:block md:w-auto"
            id="navbar-default"
          >
            <ul className="flex flex-col md:flex-row">
              <li>
                <Link
                  href="/showcase/"
                  className="block rounded-full px-3 py-1 hover:bg-accent-bg-2 lg:px-4"
                >
                  Showcase
                </Link>
              </li>
              <li>
                <Link
                  href="/blog/"
                  className="block rounded-full px-3 py-1 hover:bg-accent-bg-2 lg:px-4"
                >
                  Blog
                </Link>
              </li>
              <li>
                <div className="relative">
                  <button
                    type="button"
                    id="resources-toggle"
                    className="flex items-center rounded-full px-3 py-1 hover:bg-accent-bg-2 lg:px-4"
                    aria-expanded={showResources ? true : false}
                    onClick={() => setShowResources(!showResources)}
                    // onMouseOver={() => setShowResources(true)}
                    // onMouseOut={() => setTimeout(() => setShowResources(false), 1000)}
                  >
                    <span className="mr-1">Resources</span>
                    <Icon
                      name="chevron-down"
                      className="h-4 w-4"
                      strokeWidth="2.5"
                    />
                  </button>

                  {showResources && (
                    <nav
                      ref={resourcesMenu}
                      className="absolute left-1/2 z-10 mt-3 flex w-screen max-w-max -translate-x-1/2 px-4"
                    >
                      <NavResources />
                    </nav>
                  )}
                </div>
              </li>
              <li>
                <Link
                  href="/contact/"
                  className="block rounded-full px-3 py-1 hover:bg-accent-bg-2 lg:px-4"
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  href="/services/"
                  className="block rounded-full px-3 py-1 hover:bg-accent-bg-2 lg:px-4"
                >
                  Services
                </Link>
              </li>
              {theme && (
                <li>
                  <Link
                    href="/themes/"
                    className="block rounded-full px-3 py-1 hover:bg-accent-bg-2 lg:px-4"
                  >
                    Themes
                  </Link>
                </li>
              )}
              <li>
                <Link
                  href="/bundle/"
                  className="block rounded-full px-3 py-1 hover:bg-accent-bg-2 lg:px-4 text-accent-txt-1 border border-accent-brd"
                >
                  ⚡ Bundle
                </Link>
              </li>
            </ul>
          </div>

          {/* Main theme CTA */}
          {theme ? (
            <ButtonArrow
              link={`${theme.purchase}`}
              text={`Buy ${theme.name}`}
              color="brand"
              size=""
              product={theme.title}
              linkClass="lemonsqueezy-button py-1.5"
              target="_blank"
              rel="noreferrer"
            />
          ) : (
            <ButtonArrow
              link="/themes/"
              text="Pro Themes"
              color="brand"
              size=""
              linkClass="py-1.5"
            />
          )}

          {/* Nav button */}
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="ml-2 inline-flex items-center rounded-full p-2 text-sm text-accent-txt-1 ring-2 ring-accent-brd hover:bg-accent-bg-2 focus:bg-accent-bg-2 md:hidden"
            aria-controls="navbar-default"
            aria-expanded={showMobileMenu ? true : false}
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          >
            <span className="sr-only">Open main menu</span>
            <Icon name="menu" className="h-4 w-4" strokeWidth="2.5" />
          </button>
        </div>
      </nav>

      {showMobileMenu && (
        <NavMobile
          setShowMobileMenu={setShowMobileMenu}
          showMobileMenu={showMobileMenu}
        />
      )}
    </header>
  );
}
